import _ from "lodash";


const numbers = {
    'ноль': 0,
    'нуль': 0,
    'один': 1,
    'одна': 1,
    'два': 2,
    'две': 2,
    'три': 3,
    'четыре': 4,
    'пять': 5,
    'шесть': 6,
    'семь': 7,
    'восемь': 8,
    'девять': 9,
    'десять': 10,
    'одинадцать': 11,
    'одиннадцать': 11,
    'двенадцать': 12,
    'тринадцать': 13,
    'четырнадцать': 14,
    'пятнадцать': 15,
    'шестнадцать': 16,
    'семнадцать': 17,
    'восемнадцать': 18,
    'девятнадцать': 19,
    'двадцать': 20,
    'тридцать': 30,
    'сорок': 40,
    'пятьдесят': 50,
    'шестьдесят': 60,
    'семьдесят': 70,
    'восемьдесят': 80,
    'девяносто': 90,
    'сто': 100,
    'двести': 200,
    'триста': 300,
    'четыреста': 400,
    'пятьсот': 500,
    'шестьсот': 600,
    'семьсот': 700,
    'восемьсот': 800,
    'девятьсот': 900,
}

const whole = {
    'двадцать': 20,
    'тридцать': 30,
    'сорок': 40,
    'пятьдесят': 50,
    'шестьдесят': 60,
    'семьдесят': 70,
    'восемьдесят': 80,
    'девяносто': 90,
}

const last = {
    'один': 1,
    'два': 2,
    'три': 3,
    'четыре': 4,
    'пять': 5,
    'шесть': 6,
    'семь': 7,
    'восемь': 8,
    'девять': 9,
    'десять': 10,
    'одинадцать': 11,
    'одиннадцать': 11,
    'двенадцать': 12,
    'тринадцать': 13,
    'четырнадцать': 14,
    'пятнадцать': 15,
    'шестнадцать': 16,
    'семнадцать': 17,
    'восемнадцать': 18,
    'девятнадцать': 19,
}

const plural = {
  'тысяча': 1000,
  'тысячи': 1000,
  'тысяч': 1000,
  'миллион': 1000000,
  'миллиона': 1000000,
  'миллионо': 1000000,
  'миллиард': 1000000000,
  'миллиарда': 1000000000,
  'миллиардо': 1000000000,
  'триллион': 1000000000000,
  'триллиона': 1000000000000,
  'триллионов': 1000000000000,
}

export function getNumber(text) {
    let result = 0;
    _.each(text.split(' '), function (n) {
        if(_.has(numbers, n)){
            result += numbers[n];
        }
        if(_.has(plural, n)){
            result = plural[n] * Math.max(result, 1);
        }
    });

    return result;
}

export function getNumbers(text, isFinal) {
    const textArray = text.split(' ');

    let results = [];
    let result = 0;
    let isPart = false;

    _.each(textArray, function (n, index) {
        if(_.has(numbers, n)){

            if(_.has(whole, n)){
                if(isPart){
                    results.push(result);
                    result = numbers[n];
                }else{
                    isPart = true;
                    result += numbers[n];
                    if(index === textArray.length-1 && isFinal) {
                        results.push(result);
                    }
                }
            }else{

                if(_.has(last, n)) {
                    if(isPart) {
                        result += numbers[n];
                        results.push(result);
                        result = 0;
                        isPart = false
                    }else{
                        if(isFinal){
                            result += numbers[n];
                            results.push(result);
                            result = 0;
                            isPart = false
                        }
                    }
                }else{
                    isPart = false;
                    result += numbers[n];
                }
            }
        }
        if(_.has(plural, n)){
            if(isPart){
                results.push(result);
                result = plural[n] * Math.max(result, 1);
            }else{
                isPart = true;
                result += plural[n] * Math.max(result, 1);
            }

        }
    });

    return results;
}

export function testNumbers() {
    const numbers = getNumbers('один тысяча семьсот шестьдесят пять Одна тысяча один двадцать семь двенадцать сто сорок восемь семьсот пятьдесят девяносто восемь сорок девять пятьдесят пятьдесят один семь восемь девять шестьдесят шестьдесят шестьдесят семь сорок');
    console.log(_.isEqual(numbers, [1, 1765, 1001, 27, 12, 148, 750, 98, 49, 50, 51, 7, 8, 9, 60, 60, 67, 40]), numbers);
}

// testNumbers();
import utils from "./utils";
import stateManager from './stateManager'
import _ from "lodash";
import {questionsMeta, structure} from "./structure";
import axios from "axios";
import Inputmask from "inputmask";

let terms = function () {

};

export default terms;
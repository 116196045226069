import {questionsMeta} from "../structure";
import stateManager from "../stateManager";
import utils from "../utils";
import {Howl} from "howler";

export const task_6_1 = function(currentState){
    const $page = $('.page-question');
    const $options = $page.find('#answer .task .ph-wrapper');
    const $buttonSkip = $page.find('.button.next');

    let meta = questionsMeta[currentState];

    let answer = 0;
    let answerStartTime = 0;
    let allowToFinish = true;
    let sound = null;


    let position = 0;
    let cursor = 0;
    let isTargetHit = false;
    const maxCursor = meta.options.length;
    const targetPositions = meta.letterPositions;

    const processAnswer = function (){

        sound = new Howl({
            src: ['/assets/audio/' + meta.audioLetters],
            usingWebAudio: false,
            onplayerror: function (id, error){
                console.log('error');
                console.log(error);
            },
            onplay: function(id){
                // console.log('starting to play: '+id);
            },
            onend: function(id){
                // console.log('finishing to play: '+id);
                if(targetPositions.includes(cursor)){
                    $options.eq(position).addClass('off');
                    position++;
                    isTargetHit = false;
                    $options.removeClass('active').eq(position).addClass('active');
                }
                cursor++;
                if(cursor < maxCursor){
                    sound.play('letter'+cursor);
                }else{
                    setTimeout(function (){
                        answer = Math.min(meta.letterPositions.length, Math.max(0, answer));
                        //console.log(answer);
                        finish(answer);
                    }, 1000);
                }
            }
        });

        let sprites = {};
        _.each(meta.options, function (option, index){
            sprites['letter'+index] = option;
        });
        sound._sprite = sprites;
        sound.play('letter'+cursor);

    };

    const spaceAction = function (){
        const $option = $options.eq(position);

        if(targetPositions.includes(cursor)){
            if(!isTargetHit) {
                $option.addClass('correct').on(
                    "webkitAnimationEnd oanimationend msAnimationEnd animationend",
                    function () {
                        $option.removeClass("correct");
                    }
                );
                $option.addClass('on').find('.placeholder').text('A');
                $options.removeClass('active').eq(position + 1).addClass('active');
                isTargetHit = true;
                answer++;
            }
        }else{
            answer--;
            $option.addClass('wrong').on(
                "webkitAnimationEnd oanimationend msAnimationEnd animationend",
                function() {
                    $option.removeClass("wrong");
                }
            );
        }
    };

    $(document).bind('keypress', function(e) {
        if (e.which === 32){//space bar
            e.preventDefault();
            spaceAction();
        }
    });
    $page.find('.button.space').on('click', function(){
        const $button = $(this);
        $button.addClass('taped').on(
            "webkitAnimationEnd oanimationend msAnimationEnd animationend",
            function() {
                $button.removeClass("taped");
            }
        );

        spaceAction();
    });

    $buttonSkip.on('click', function(){
        finish(Math.min(meta.letterPositions.length, Math.max(0, answer)));
    });

    const finish = function (answer){
        if(allowToFinish) {
            sound.stop();
            allowToFinish = false;
            $page.find('.footer .button').removeClass('active');
            $page.find('.question').addClass('done');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 2500);
        }
    }

    const startTask = () => {
        answerStartTime = Date.now();
        setTimeout(processAnswer, 1300);
        const title = meta.titleMobile && utils.isMobileAndTablet() ? meta.titleMobile : meta.title;
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(utils.formatTitle(title[1])).fadeIn(200));
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    startTask();

};
import _ from "lodash";
import axios from "axios";
import QRCode from "qrcode";
import utils from "./utils";

let pdf = function (params) {
    const $page = $('.page-pdf');

    $page.find('.section.cover img').attr('src', '/assets/cover_logo_' + process.env.SOURCE + '.svg');

    const uuid = params.uuid;

    ////
    // request test results from server
    ////
    axios.post(process.env.API_URL + 'api/results/calculate', {
        uuid: uuid
    })
        .then(function (response) {
            response = response.data;

            if(response.status){
                processAnswer(response.data.results);
            }else{
                //display error inside PDF
                $page.html(response.message);
            }



        })
        .catch(function (error) {
            console.log(error);
        });

    const processAnswer = function (results) {

        const $results = $page.find('.results');

        let table = _.sortBy(results.table, function (result) {
            return 100 / result.points * result.score;
        });

        _.each(table, function (result, categoryId) {

            let resultElem = '<div id="'+result.categoryId+'" class="section result '+result.categoryId+'">\n' +
                '                <div class="left">\n' +
                '                    <div class="trigger trigger-icon">\n' +
                '                        <img src="/assets/' + result.categoryId + '.svg">\n' +
                '                    </div>\n' +
                '                    <div class="title">' + result.category + '</div>\n' +
                '                </div>\n' +
                '                <div class="right">\n' +
                '                    <div class="bar ' + result.level + '">\n' +
                '                        <div class="info">\n' +
                '                            <div class="tag">'+result.short+'</div>\n' +
                '                        </div>\n' +
                '                        <div class="scale">\n' +
                '                            <div class="line" style="width: ' + Math.max(10, (100 / result.points * result.score)) + '%;"><div></div></div>\n' +
                '                        </div>\n' +
                '                    </div>\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        </div>'

            $results.append($(resultElem));

        });

        console.log(results);

        $('body').css('background', 'white');

        $page.find('.section.top .descr').html(results.summary.short);
        $page.find('.section.top .graph .tag').html(results.summary.level_tag);
        $page.find('.section.top .graph img').attr('src', '/assets/state_'+results.summary.level+'.png');
        $page.find('.section.top .graph').addClass(results.summary.level);
        $page.find('.section.top .graph path:last-child').css('stroke-dashoffset', 484 - (484 / 100 * Math.max(10, (100 / results.summary.points * results.summary.score))));

        $page.find('.section.state .descr').eq(0).html(results.state.short);
        $page.find('.section.state .graph .tag').html(results.state.level_tag);
        $page.find('.section.state .graph img').attr('src', '/assets/state_'+results.state.level+'.png');
        $page.find('.section.state .graph').addClass(results.state.level);
        $page.find('.section.state .graph path:last-child').css('stroke-dashoffset', 484 - (484 / 100 * Math.max(10, (100 / results.state.points * results.state.score))));
        if(results.state.score === 0){
            $page.find('.section.state .graph').remove();
        }

        // $page.find('.section.promo .left .title').html(results.summary.recommendation);
        // $page.find('.section.recs .panels .panel:first-child .descr').html(results.summary.recs[0]);
        // $page.find('.section.recs .panels .panel:last-child .descr').html(results.summary.recs[1]);


        const $details = $page.find('.section.state .detailsState .descr');
        const paragraphs = _.compact(results.state.description.split(/\r?\n/));
        console.log(paragraphs);
        _.each(paragraphs, function(par){
            $details.append($('<div class="par">'+par+'</div>'));
        });

    };

    // const link = process.env.BASE_URL+'contact/'+uuid;
    //
    // QRCode.toCanvas($page.find('#qrcode')[0], link, {color: {dark: '#4095F2FF'}}, function (error) {
    //     if (error) console.error(error)
    //     //console.log('success!');
    // });

};

export default pdf;
const io = require("socket.io-client");
const RecordRTC = require("recordrtc");
const _ = require("lodash");
const words2numbers = require("./words2numbers");
const {enableBodyScroll} = require("body-scroll-lock");

let socket;
let recorder;
let stream;

module.exports = {


    init: async () => {
        let micStatus = false;
        await navigator.mediaDevices.getUserMedia({audio: { noiseSuppression: false }})
            .then((s) => {
                //console.log('PERMISSION GRANTED!');
                stream = s;
                micStatus = true;

                // function connectToSpeaker(remoteAudioStream, gain) {
                //     const context = window.AudioContext || window.webkitAudioContext;
                //     const audioNode = context.createMediaStreamSource(remoteAudioStream);
                //     const gainNode = context.createGain();
                //
                //     gainNode.gain.value = gain;
                //     audioNode.connect(gainNode);
                //     gainNode.connect(context.destination);
                // }
                //
                // connectToSpeaker(stream, 5);
            })
            .catch((e) => {
                //console.log('PERMISSION DENIED!');
            });

        return micStatus;
    },

    getDeviceName: async function(){
        let tracks = stream.getTracks();
        let deviceName = '';
        for(let i = 0; i < tracks.length; i++){
            let device = await this.getDevice(tracks[i].getSettings().deviceId)
            if(device) deviceName = device;
        }
        return deviceName;
    },

    getDevice: async function(id){
        let devices = await navigator.mediaDevices.enumerateDevices();
        let foundDevice = false;
        _.each(devices, function(device) {
            if(device.deviceId === id && device.kind === 'audioinput'){
                foundDevice = device.label !== undefined ? device.label : 'Default';
            }
        });
        return foundDevice;
    },

    startRecording: async function (wordsCallback) {

        socket = io.io(process.env.API_URL + 'ws/speech');
        socket.on('connect_error', function (e) {
            console.log("There seems to be an issue with the WS connection!");
        });


        if(stream === undefined || !stream.active){
            await this.init();
        }

        if(stream !== undefined && stream.active) {

            recorder = new RecordRTC(stream, {
                recorderType: RecordRTC.StereoAudioRecorder,
                type: 'audio',
                mimeType: 'audio/wav',
                numberOfAudioChannels: 1,
                // sampleRate: stream.getTracks()[0].getSettings().sampleRate,
                desiredSampRate: 48000,
                timeSlice: 200,

                ondataavailable: (blob) => {
                    socket.emit('speech', blob);
                },
                disableLogs: true,
            });



            recorder.startRecording();

            let words = [];

            socket.on('text', function (message) {
                message = JSON.parse(message);
                if (!_.isEmpty(message)) {
                    let isFinal = false;
                    if (_.has(message, 'final')) {
                        isFinal = true;
                    }
                    if (_.has(message, 'text')) {
                        words = _.union(words, message.text.split(' '));
                        // $page.find('.title').text(message.text);
                    }
                    // $page.find('.task input').val(words2numbers(message.text));

                    const numbers = words2numbers.getNumbers(message.text, isFinal);

                    wordsCallback(words, message.text, numbers, isFinal);
                }

            });

        }
    },

    finishRecording: function () {
        //console.log('ending recorder');
        socket.emit('finishSession', '');
        socket.disconnect();
        recorder.stopRecording();
    },

    closeMicrophone: function(){
        stream.getTracks().forEach(function (track) {
            track.stop();
        });
    }
}
import utils from "./utils";
import _ from "lodash";
import axios from "axios";
import Inputmask from "inputmask";

let contact = function (params) {
    // console.log(params.uuid);
    const $page = $('.page-contact');

    $page.find('.legal_year').html(new Date().getFullYear());
    $page.find('.partner_logo').attr('src', '/assets/logo_' + process.env.SOURCE + '.svg')

    const uuid = params.uuid;
    const $firstName = $page.find('.field.first_name input');
    const $lastName = $page.find('.field.last_name input');
    const $phone = $page.find('.field.phone input');

    let isSubmitActive = true;

    const phoneMaskFormat = '+7 (999) 999-9999';
    Inputmask({
        mask: phoneMaskFormat,
        showMaskOnHover: false,
        clearMaskOnLostFocus: false,
        onKeyValidation: function () {
        },
        onBeforePaste: function (pastedValue, opts) {
            if (Array.from(pastedValue)[0] === '8') {
                return pastedValue.substring(1);
            } else if (Array.from(pastedValue)[0] === '+') {
                return pastedValue.substring(2);
            } else {
                return pastedValue;
            }
        }
    }).mask($phone[0]);

    $firstName.on('input', function () {
        if ($(this).val()) {
            $(this).parent().find('.placeholder').removeClass('active');
        } else {
            $(this).parent().find('.placeholder').addClass('active');
        }
    });

    $lastName.on('input', function () {
        if ($(this).val()) {
            $(this).parent().find('.placeholder').removeClass('active');
        } else {
            $(this).parent().find('.placeholder').addClass('active');
        }
    });

    const processForm = function () {
        if (isSubmitActive) {
            $firstName.parent().removeClass('error');
            $lastName.parent().removeClass('error');
            $phone.parent().removeClass('error');

            const isValidFirstName = $firstName.val().length > 0;
            const isValidLastName = $lastName.val().length > 0;
            const isValidPhone = Inputmask.isValid($phone.val(), {alias: phoneMaskFormat});

            if (!isValidFirstName) {
                $firstName.parent().addClass('error');
            }
            if (!isValidLastName) {
                $lastName.parent().addClass('error');
            }
            if (!isValidPhone) {
                $phone.parent().addClass('error');
            }

            const isValidForm = isValidFirstName && isValidLastName && isValidPhone;

            if (isValidForm) {
                isSubmitActive = false;

                const user = {
                    uuid: uuid,
                    firstName: $firstName.val(),
                    lastName: $lastName.val(),
                    phone: $phone.val()
                };

                //console.log(user);

                axios.post(process.env.API_URL + 'api/user/update', {
                    user: user
                })
                    .then(function (response) {
                        response = response.data;

                        if(response.status){
                            $page.find('.contact .main').removeClass('active');
                            $page.find('.contact .success').addClass('active');
                        }else{
                            utils.showNotification(response.message);
                            isSubmitActive = false;
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    };

    $page.find('.contact .submit').on('click', processForm);


};

export default contact;
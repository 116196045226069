import utils from "./utils";
import stateManager from './stateManager'
import _ from "lodash";
import {questionsMeta, structure} from "./structure";
import axios from "axios";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import FileSaver from 'file-saver';
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';

let results = function () {
    const $page = $('.page-results');

    $page.find('.legal_year').html(new Date().getFullYear());
    $page.find('.partner_logo').attr('src', '/assets/logo_' + process.env.SOURCE + '.svg');
    $page.find('.section.cover').css('background-image', 'url("/assets/cover_' + process.env.SOURCE + '.jpg")');
    $page.find('.section.cover img').attr('src', '/assets/cover_logo_' + process.env.SOURCE + '_white.svg');

    const uuid = utils.getFromLS('uuid');

    let allResults = false;


    const $buttonBuy = $page.find('.button.checkout');
    const $promocodeInput = $('#promocode');
    const $promocodeStatus = $('.promocode .status');
    const $promocodeButton = $('.promocode .button');

    let activeProduct = 1;
    let activePromocode = '';

    let productsData = {};


    const processSlider = function(){
        new Swiper('.plans-slider', {
            direction: 'horizontal',
            loop: false,
            slidesPerView: 3,
            spaceBetween: 20,
            breakpoints: {
                340: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
            navigation: {
                nextEl: '.arrow.slider-right',
                prevEl: '.arrow.slider-left'
            }
        });
    }

    const format = function (number) {
        return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    ////
    // request test results from server
    ////
    axios.post(process.env.API_URL + 'api/results/calculate', {
        uuid: uuid
    })
        .then(function (response) {
            response = response.data;

            if(response.status){



                if(!response.data.isPaid){
                    // console.log('not paid');

                    $page.find('.top').remove();
                    $page.find('.section.promo').remove();
                    $page.find('.locked').addClass('active');


                    axios.post(process.env.API_URL + 'api/prices/get', {
                        uuid: uuid,
                        variant: utils.getFromLS('variant')
                    })
                        .then(function (res) {
                            res = res.data;
                            if(res.status){
                                productsData = res.data.products;
                                utils.setToLS('variant', res.data.variant);

                                _.each(productsData, function(product){
                                    $('.plan.plan'+product.type+ ' .price-month').text(format(product.price/product.type));
                                    if(product.oldPrice) {
                                        $('.plan.plan' + product.type + ' .old').text(format(product.oldPrice));
                                    }
                                    $('.plan.plan'+product.type+ ' .total .price span').text(format(product.price));
                                    $('.plan.plan'+product.type+ ' .badge-text').text(product.discount);
                                    $('.plan.plan'+product.type+ ' .button.checkout').attr('id', 'product-'+product.id);
                                });

                                processSlider();

                                processAnswer(response.data.results, false);
                                $page.find('.block.loading').removeClass('active');

                            }else{
                                console.log('Error getting data from server');
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                }else{
                    processAnswer(response.data.results, true);

                    $page.find('.locked').remove();
                    $page.find('.results.disabled').remove();
                    $page.find('.block.loading').removeClass('active');
                }
            }else{
                utils.notifyError(response.message);
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    const processAnswer = function (results, showResults) {

        $page.find('.section.state .descr').html(results.state.short);
        $page.find('.section.state .scale').addClass(results.state.level);
        $page.find('.section.state .scale .line').css('width', Math.max(10, (100 / results.state.points * results.state.score)) + '%');
        $page.find('.section.state .graph .tag').html(results.state.level_tag);
        $page.find('.section.state .graph img').attr('src', '/assets/state_'+results.state.level+'.png');
        $page.find('.section.state .graph').addClass(results.state.level);
        $page.find('.section.state .graph path:last-child').css('stroke-dashoffset', 484 - (484 / 100 * Math.max(10, (100 / results.state.points * results.state.score))));
        if(results.state.score === 0){
            $page.find('.section.state .info .button').remove();
            $page.find('.section.state .graph').remove();
            $page.find('.section.state .scale').remove();
        }

        allResults = results;

        if(showResults) {

            $page.find('.section.top .descr').html(results.summary.short);
            $page.find('.section.top .scale').addClass(results.summary.level);
            $page.find('.section.top .scale .line').css('width', Math.max(10, (100 / results.summary.points * results.summary.score)) + '%');
            $page.find('.section.top .graph .tag').html(results.summary.level_tag);
            $page.find('.section.top .graph img').attr('src', '/assets/state_'+results.summary.level+'.png');
            $page.find('.section.top .graph').addClass(results.summary.level);
            $page.find('.section.top .graph path:last-child').css('stroke-dashoffset', 484 - (484 / 100 * Math.max(10, (100 / results.summary.points * results.summary.score))));

            const $results = $page.find('.results');
            $results.empty();

            let table = _.sortBy(results.table, function (result) {
                return 100 / result.points * result.score;
            });

            _.each(table, function (result, categoryId) {

                let resultElem = '<div id="' + result.categoryId + '" class="section result ' + result.categoryId + '">\n' +
                    '                <div class="left">\n' +
                    '                    <div class="trigger trigger-icon">\n' +
                    '                        <img src="/assets/' + result.categoryId + '.svg">\n' +
                    '                    </div>\n' +
                    '                    <div class="title">' + result.category + '</div>\n' +
                    '                </div>\n' +
                    '                <div class="right">\n' +
                    '                    <div class="bar ' + result.level + '">\n' +
                    '                        <div class="info">\n' +
                    '                            <div class="tag">' + result.short + '</div>\n' +
                    '                        </div>\n' +
                    '                        <div class="scale">\n' +
                    '                            <div class="line" style="width: ' + Math.max(10, (100 / result.points * result.score)) + '%;"><div></div></div>\n' +
                    '                        </div>\n' +
                    '                    </div>\n' +
                    '                </div>\n' +
                    '            </div>\n' +
                    '        </div>'

                $results.append($(resultElem));

            });


        }

    };


    const closeModal = function (modal) {
        const $modal = $('.block.' + modal);
        $modal.removeClass('active');
        enableBodyScroll($modal[0]);
    };
    const activateCloseListeners = function (modal) {
        const $modalElem = $page.find('.block.' + modal + ' .wrapper');
        $modalElem.on('click', function (e) {
            if (e.target !== e.currentTarget) return;
            // console.log('clicked outside modal frame');
            closeModal(modal);
        });
        $modalElem.find('.close').on('click', function (e) {
            // console.log('clicked close button');
            closeModal(modal);
        });
    };

    activateCloseListeners('detailsState');
    activateCloseListeners('details');

    $page.find('.button.detailsState').on('click', function () {
        const $modal = $page.find('.block.detailsState');
        disableBodyScroll($modal[0]);
        // const elemId = $(this).attr('id');
        $modal.find('.descr').html(allResults.state.description);
        $modal.addClass('active');
    });

    $page.on('click', '.section.result', function () {
        const $modal = $page.find('.block.details');
        disableBodyScroll($modal[0]);
        const elemId = $(this).attr('id');
        const entry = _.find(allResults.table, { 'categoryId': elemId });
        $modal.find('h3').html(entry.category);
        $modal.find('.descr').html(entry.description);
        $modal.addClass('active');
    });

    $page.find('.button.contact').on('click', function () {
        const $modal = $page.find('.block.contact');
        disableBodyScroll($modal[0]);
        $modal.addClass('active');
    });
    



    ///////////
    // ORDER //
    ///////////
    
    $promocodeInput.on('input', function(){
        if($(this).val() === ''){
            // $promocodeStatus.removeClass('active')
            $promocodeButton.addClass('disabled');
        }else{
            $promocodeButton.removeClass('disabled');
        }
    });

    $promocodeButton.on('click', function(){
        if($promocodeInput.val() !== '' && !$(this).hasClass('disabled')){
            // console.log('proceed recalculate');
            recalculate($promocodeInput.val());
        }
    });


    let isPromoActive = true;
    const recalculate = function(promocode){
        if(isPromoActive) {
            isPromoActive = false;

            $promocodeStatus.removeClass('active').removeClass('success');

            axios.post(process.env.API_URL + 'api/price/recalculate', {
                product: activeProduct,
                promocode: promocode
            })
                .then(function (response) {
                    response = response.data;
                    if(response.status){
                        // const data = {
                        //     price: 10990,
                        //     discount: -1099.00,
                        //     total: 9891.00,
                        //     promoStatus: true,
                        //     promocode: 'PODAROK2024'
                        // }
                        const data = response.data;

                        if (data.promoStatus) {
                            $promocodeStatus.text('Активирована скидка: -' + data.discountRaw + '%').addClass('success active');
                            activePromocode = data.promocode;
                            // console.log(activePromocode)
                        } else {
                            $promocodeStatus.text('Промокод не найден').addClass('active');
                        }
                    }else{
                        $promocodeStatus.text('Промокод не найден').addClass('active');
                    }
                })
                .then(function(){
                    isPromoActive = true;
                })
                .catch(function (error) {
                    $promocodeStatus.text('Ошибка соединения с сервером').addClass('active');
                    console.log(error);
                });
        }

    };


    $buttonBuy.on('click', function(){
        if(!$(this).hasClass('disabled')){
            $(this).addClass('loading');
            activeProduct = $(this).attr('id').split('-')[1];
            purchase();
        }
    });


    let isButtonActive = true;
    const purchase = function () {
        if (isButtonActive) {
            isButtonActive = false;

            $buttonBuy.addClass('disabled');
            $promocodeStatus.removeClass('active');

            const referer = utils.getFromLS('referer');

            const data = {
                product: activeProduct,
                promocode: activePromocode,
                test: uuid,
                referral: referer
            };

            // console.log(data);

            axios.post(process.env.API_URL + 'api/payment/create', data)
                .then(function (response) {
                    response = response.data;
                    if(response.status){
                        window.location.href = response.data.paymentURL;
                    }else{
                        // console.log(response.message);
                        let errorMessage = response.message;
                        $promocodeStatus.html(errorMessage).addClass('active');
                        isButtonActive = true;
                        $buttonBuy.removeClass('disabled');
                    }
                })
                .then(()=>{
                    $buttonBuy.removeClass('disabled loading');
                    isButtonActive = true;
                })
                .catch(function (error) {
                    console.log(error);
                    isButtonActive = true;
                    $buttonBuy.removeClass('disabled');
                });

        }
    };


};

export default results;
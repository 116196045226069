import axios from "axios";

let exercise = function (params) {
    const $page = $('.page-exercise');
    const pagesDir = '/pages/exercise/';

    const uuid = params.uuid;

    ////
    // request exercise structure from server
    ////
    axios.post(process.env.API_URL + 'api/exercise/get', {
        uuid: uuid
    })
        .then(function (response) {
            response = response.data;

            if(response.status){
                processData(response.data);
            }else{
                //display error inside PDF
                $page.html(response.message);
            }
        })
        .catch(function (error) {
            console.log(error);
            $page.html('Error loading data: '+uuid);
        });

    const loadStatic = function (index, type){
        fetch(pagesDir + type + '/' + type + '_' + index + '.html')
            .then(response => response.text())
            .then(text => { $page.find('.sections').append($(text)); });
    };

    const loadExercises = function (index, content, count){
        const self = loadExercises;
        fetch(pagesDir + content[index].id + '.html')
            .then(response => response.text())
            .then(text => {
                let $section = $(text);
                if(content[index].showHeading){
                    $section.prepend('<div class="heading">'+content[index].heading+'</div>');
                }
                $section.find('.label span').text(content[index].label);
                $page.find('.sections').append($section);
                if(index !== content.length-1){
                    self(index+1, content, count);
                }else{
                    loadStatic(count, 'psy');
                }
            });
    };

    const processData = function (content) {

        fetch(pagesDir + 'cover' + '/' + 'cover' + '_' + content.count + '.html')
            .then(response => response.text())
            .then(text => {
                $page.find('.sections').append($(text));
                loadExercises(0, content.exercises, content.count);
            });

    };


};

export default exercise;
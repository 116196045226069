import utils from "./utils";
import _ from "lodash";
import axios from "axios";
import {structure} from "./structure";

let onetouch = function (params) {

    const uuid = params.uuid;
    // console.log(params.uuid);

    axios.post(process.env.API_URL + 'api/user/check', {
        uuid: uuid
    })
        .then(function (response) {
            response = response.data;

            if(response.status){
                const isValid = response.data.isValid;
                if(isValid){
                    // console.log('VALID!!!');
                    utils.clearLS();
                    utils.setToLS('uuid', response.data.testUUID);
                    structure.questions = response.data.structure;
                    window.location.href = '/';
                }else{
                    // console.log('Not valid!!!');
                    utils.redirectToHome();
                }
            }else{
                utils.showNotification(response.message);
            }

        })
        .catch(function (error) {
            console.log(error);
        });

};

export default onetouch;
import {questionsMeta} from "../structure";
import stateManager from "../stateManager";
import utils from "../utils";

export const task_7_1 = function(currentState){
    const $page = $('.page-question');
    const $buttonSkip = $page.find('.button.next');
    // $buttonSkip.addClass('disabled');

    let meta = questionsMeta[currentState];
    let answer = [];
    let answerStartTime = 0;
    let allowToFinish = true;

    const optionsCount = 8;
    const maxSelectedCount = 8;

    const processAnswer = function (){

        const $options = $page.find('#answer .task');

        $options.empty();

        let optionsSelected = 0;

        for(let i = 1; i <= optionsCount; i++){
            $('<div>', {
                class: 'color',
                id: meta.options[i-1][1]
            }).css('background-color', meta.options[i-1][0]).appendTo($options).on('click', function(){

                if(!$(this).hasClass('active')) {
                    if (optionsSelected < maxSelectedCount) {
                        $(this).addClass('active');
                        answer.push($(this).attr('id'));
                        optionsSelected++;
                    }

                    if (optionsSelected === maxSelectedCount) {
                        finish(answer);
                    }
                }
            });
        }
    }

    $buttonSkip.on('click', function(){
        finish(answer);
    });

    const finish = function (answer){
        if(allowToFinish) {
            allowToFinish = false;
            $page.find('.footer .button').removeClass('active');
            $page.find('.question').addClass('done');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 1500);
        }
    }

    const startTask = () => {
        answerStartTime = Date.now();
        processAnswer();
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(utils.formatTitle(meta.title[1])).fadeIn(200));
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    startTask();
};